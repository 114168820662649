import {defineStore} from 'pinia'
import {Helpers} from "@/helpers";
import {useClientStore} from "@/stores/client";
import dayjs from "dayjs";
import {NANO_EVENT_TYPES} from "@/scripts/constants";

export const useProductStore = defineStore({
    // name of the store
    // it is used in devtools and allows restoring state
    id: 'product',
    // a function that returns a fresh state
    state: () => ({
        product: null,
        product_contracts: null,
        products: [],
        itemLength: 0,
        loading: false,
        serverOptions: {
            page: 0,
            rows: 25,
            filters: {},
            sortField: null,
            sortOrder: null,
        }
    }),
    // optional getters
    getters: {},
    // optional actions
    actions: {
        resetProducts() {
            this.products = []
        },
        async index() {
            const newUrlQuery = {}
            const params = {
                page: this.serverOptions.page + 1,
                size: this.serverOptions.rows
            }
            if (this.serverOptions.filters.client_id?.value) {
                Helpers.addQueryToParams({client_id: this.serverOptions.filters.client_id.value}, params)
                newUrlQuery['client_id'] = this.serverOptions.filters.client_id?.value
            } else {
                await Helpers.removeQueryFromUrl("client_id")
            }
            if (this.serverOptions.filters.parent_id?.value) {
                Helpers.addQueryToParams({parent_id: this.serverOptions.filters.parent_id.value}, params)
            }
            if (this.serverOptions.filters.product_status_key?.value) {
                Helpers.addQueryToParams({product_status_key: this.serverOptions.filters.product_status_key?.value}, params)
                newUrlQuery['product_status_key'] = this.serverOptions.filters.product_status_key?.value
            } else {
                await Helpers.removeQueryFromUrl("product_status_key")
            }

            if (this.serverOptions.filters.product_type_key?.value) {
                Helpers.addQueryToParams({product_type_key: this.serverOptions.filters.product_type_key?.value}, params)
                newUrlQuery['product_type_key'] = this.serverOptions.filters.product_type_key?.value
            } else {
                await Helpers.removeQueryFromUrl("product_type_key")
            }

            if (this.serverOptions.filters.name?.value) {
                Helpers.addQueryToParams({name: this.serverOptions.filters.name?.value}, params)
                newUrlQuery['name'] = this.serverOptions.filters.name?.value
            } else {
                await Helpers.removeQueryFromUrl("name")
            }

            await Helpers.setUrlQuery(newUrlQuery)

            if (this.serverOptions.sortField) {
                Helpers.addQueryToParams({
                    sort_by: this.serverOptions.sortField,
                    sort_order: this.serverOptions.sortOrder
                }, params)
            }

            return this.getProducts(params)
        },
        get(id) {
            return this.apiClient.get(`/products/${id}`)
        },
        getProduct(id, callback = null, pageTitle = 'no title passed to getProduct (product store)') {
            this.product = null
            this.product_contracts = null
            this.get(id).then(({data}) => {
                this.product = data.product;
                // this.product_billing_periods = data.product_billing_periods;
                this.product_contracts = data.contracts
                const clientId = data.product?.client_id
                if (clientId) {
                    useClientStore().getClient(clientId, pageTitle)
                }
                if (typeof callback === 'function') {
                    callback(data)
                }
            });
        },
        getProducts(params) {
            this.loading = true
            return this.apiClient.get(`/products`, {
                params
            }).then(({data}) => {
                this.products = data.products
                this.itemLength = data.itemLength
                this.loading = false
            })
        },
        store(product) {
            this.resetProducts()
            product.contract_components_attributes = []
            if (product.contract_id) {
                product.contract_components_attributes.push({contract_id: product.contract_id})
            }
            return this.apiClient.post(`/products`, {product, client_id: product.client_id})
        },
        clone(id) {
            this.resetProducts()
            return this.apiClient.post(`/products`, {from_id: id})
        },
        update(id, product) {
            this.resetProducts()
            return this.apiClient.put(`/products/${id}`, {product})
        },
        credit(id) {
            return this.apiClient.put(`/products/${id}/credit`).then(({data}) => {
                console.log(JSON.stringify(data))
                // this.product_billing_periods = data.product_billing_periods
            }).catch((error) => {
                if (error.response) {
                    Helpers.emitter.emit('error', error.response.data.messages)
                    // console.log(error.response.status);
                } else {
                    Helpers.emitter.emit('error', "unknown error - check console!")
                    console.error(JSON.stringify(error))
                }
            })
        },
        setOnline(id, productStatusId) {
            return this.apiClient.put(`/products/${id}`, {
                product: {
                    start_at: dayjs(),
                    product_status_id: productStatusId,
                }
            }).then(({data}) => {
                console.log(JSON.stringify(data))
                // this.product_billing_periods = data.product_billing_periods
                Helpers.emitter.emit(NANO_EVENT_TYPES.PRODUCT_CHANGED)
            }).catch((error) => {
                if (error.response) {
                    Helpers.emitter.emit('error', error.response.data.messages)
                    // console.log(error.response.status);
                } else {
                    Helpers.emitter.emit('error', "unknown error - check console!")
                    console.error(JSON.stringify(error))
                }
            })
        },
        mergeProduct(id) {
            return this.apiClient.put(`/products/${id}/merge`).then(({data}) => {
                console.log(JSON.stringify(data))
                // this.product_billing_periods = data.product_billing_periods
                Helpers.emitter.emit(NANO_EVENT_TYPES.PRODUCT_CHANGED)
            }).catch((error) => {
                if (error.response) {
                    Helpers.emitter.emit('error', error.response.data.messages)
                    console.log(error.response.data.messages);
                    // console.log(error.response.status);
                } else {
                    Helpers.emitter.emit('error', "unknown error - check console!")
                    console.error(JSON.stringify(error))
                }
            })
        },
        copyProductTypeToProductBillingPeriods(id) {
            return this.apiClient.put(`/products/${id}/copy_type_to_billing_periods`).then(() => {
                Helpers.emitter.emit(NANO_EVENT_TYPES.PRODUCT_CHANGED)
            }).catch((error) => {
                if (error.response) {
                    Helpers.emitter.emit('error', error.response.data.messages)
                    console.log(error.response.data.messages);
                    // console.log(error.response.status);
                } else {
                    Helpers.emitter.emit('error', "unknown error - check console!")
                    console.error(JSON.stringify(error))
                }
            })
        },
    },
})
