import {defineStore} from 'pinia'
import i18n from '../i18n'

export const useSetupStore = defineStore({
    // name of the store
    // it is used in devtools and allows restoring state
    id: 'setup',
    // a function that returns a fresh state
    state: () => ({
        loading: {}, // stores e.g. clientTypes: true while loading
        // to prevent multiple GETs
        // if subcomponent calls getClientTypes on each call
        dataTableDefaults: {
            rowsPerPageOptions: [25, 50, 100, 1000]
        },

        clientEventTypes: [],
        clientStatuses: [],
        clientTypes: [],
        contractStatuses: [],
        countries: [],
        invoiceDeliveryMethods: [],
        invoiceStatuses: [],
        legalForms: [],
        legalInfoTypes: {
            '': '-',
            1: 'Hausdaten als Impressum',
            2: 'Hausdaten + Rechnungsanschrift als Impressum',
            3: 'Hausdaten + Impressumsanschrift',
            4: 'Keine Hausdaten, nur Impressumsanschrift',
            5: 'Hausdaten + MANET-Impressum',
            6: 'MANET-Impressum',
        },
        paymentMethods: [],
        privacyPolicyVersions: [],
        productBillingPeriodStatuses: [],
        productDurations: [],
        productPaymentCycles: [],
        productRenewalSettings: [],
        productStatuses: [],
        productTypeCategories: [],
        productTypes: [],
        registerCourts: [],
        socialMediaTypes: {
            facebook: 'Facebook', // <i class="fa-brands fa-instagram"></i>
            instagram: 'Instagram',
            twitter: 'Twitter',
            tiktok: 'Tiktok',
        },
        terminationReasons: [],
        wcaRequirements: [],

        serverOptions: {
            page: 1,
            rowsPerPage: 100,
            sortBy: 'age',
            sortType: 'desc',
        },
        axiosLoading: 0,
    }),
    // optional getters
    getters: {
        activeContractStatusIds() {
            return this.contractStatuses.filter((cs) => cs.active).map((cs) => cs.id)
        },
        activeProductStatusIds() {
            return this.productStatuses.filter((ps) => ps.active).map((ps) => ps.id)
        },
        activeProductPaymentCycleIds() {
            return this.productPaymentCycles.filter((pc) => pc.active).map((pc) => pc.id)
        },
    },
    // optional actions
    actions: {
        getGeneric(camelCaseName, underscoredName) {
            // console.log(camelCaseName, underscoredName)
            if (this.loading[camelCaseName] || this[camelCaseName].length) {
                return
            }
            this.loading[camelCaseName] = true
            return this.apiClient.get(`/setup/${underscoredName}`, {params: {locale: i18n.global.locale}}).then(({data}) => {
                this[camelCaseName] = data[underscoredName];
                this.loading[camelCaseName] = false
            })
        },
        getLegalForms() {
            this.getGeneric('legalForms', 'legal_forms')
        },
        getPrivacyPolicyVersions() {
            this.getGeneric('privacyPolicyVersions', 'privacy_policy_versions')
        },
        getRegisterCourts() {
            this.getGeneric('registerCourts', 'register_courts')
        },
        getCountries() {
            this.getGeneric('countries', 'countries')
        },
        getClientTypes() {
            this.getGeneric('clientTypes', 'client_types')
        },
        getClientTypeById(id) {
            return this.clientTypes.find((x) => x.id === id)
        },
        getClientEventTypes() {
            this.getGeneric('clientEventTypes', 'client_event_types')
        },
        getClientEventTypeById(id) {
            return this.clientEventTypes.find((x) => x.id === id)
        },
        getClientStatuses() {
            this.getGeneric('clientStatuses', 'client_statuses')
        },
        getClientStatusById(id) {
            return this.clientStatuses.find((x) => x.id === id)
        },
        getProductTypes() {
            this.getGeneric('productTypes', 'product_types')
        },
        getProductTypeById(id) {
            const productType = this.productTypes.find((pt) => pt.id === id)
            // if (!productType) {
            //     throw new Error(`failed to find productType with id ${id}`)
            // }
            return productType
        },
        getProductStatuses() {
            this.getGeneric('productStatuses', 'product_statuses')
        },
        getProductStatusById(id) {
            return this.productStatuses.find((pt) => pt.id === id)
        },
        getProductDurations() {
            this.getGeneric('productDurations', 'product_durations')
        },
        getProductDurationById(id) {
            return this.productDurations.find((pt) => pt.id === id)
        },
        getProductRenewalSettings() {
            this.getGeneric('productRenewalSettings', 'product_renewal_settings')
        },
        getProductRenewalSettingById(id) {
            return this.productRenewalSettings.find((pt) => pt.id === id)
        },
        getProductTypeCategories() {
            this.getGeneric('productTypeCategories', 'product_type_categories')
        },
        getProductTypeCategoryById(id) {
            return this.productTypeCategories.find((pt) => pt.id === id)
        },
        getProductPaymentCycles() {
            this.getGeneric('productPaymentCycles', 'product_payment_cycles')
        },
        getProductPaymentCycleById(id) {
            return this.productPaymentCycles.find((x) => x.id === id)
        },
        getProductBillingPeriodStatuses() {
            this.getGeneric('productBillingPeriodStatuses', 'product_billing_period_statuses')
        },
        getProductBillingPeriodStatusById(id) {
            return this.productBillingPeriodStatuses.find((x) => x.id === id)
        },
        getProductBillingPeriodStatusByKey(key) {
            return this.productBillingPeriodStatuses.find((x) => x.key === key)
        },
        getPaymentMethods() {
            this.getGeneric('paymentMethods', 'payment_methods')
        },
        getPaymentMethodById(id) {
            return this.paymentMethods.find((x) => x.id === id)
        },
        getProductTerminationReasons() {
            this.getGeneric('terminationReasons', 'product_termination_reasons')
        },
        getProductTerminationReasonById(id) {
            return this.terminationReasons.find((tr) => tr.id === id)
        },
        getContractStatuses() {
            this.getGeneric('contractStatuses', 'contract_statuses')
        },
        getContractStatusById(id) {
            return this.contractStatuses.find((x) => x.id === id)
        },
        getInvoiceStatuses() {
            this.getGeneric('invoiceStatuses', 'invoice_statuses')
        },
        getInvoiceStatusById(id) {
            return this.invoiceStatuses.find((x) => x.id === id)
        },
        getInvoiceDeliveryMethods() {
            this.getGeneric('invoiceDeliveryMethods', 'invoice_delivery_methods')
        },
        getInvoiceDeliveryMethodById(id) {
            return this.invoiceDeliveryMethods.find((x) => x.id === id)
        },
        getWcaRequirements() {
            this.getGeneric('wcaRequirements', 'wca_requirements')
        },
        getWcaRequirementByKey(key) {
            return this.wcaRequirements.find((wr) => wr.key === key)
        },
    },
})
