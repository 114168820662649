<template>
    <Multiselect
        :mode="multiple ? 'multiple' : 'single'"
        :canDeselect="multiple"
        :canClear="multiple"
        :hideSelected="false"
        name="client_event.client_event_type_id"
        :options=setupStore.clientEventTypes
        valueProp="id"
        label="name"
        v-model="currentIds"
        class="form-control"
        :searchable="true"
        :placeholder="$t('client_events.client_event_type')+'*'"
    >
      <template v-slot:option="{ option }">
        <i class="fa-fw me-1" :class="option.icon"
           :style="`color: ${setupStore.getClientEventTypeById(option.id)?.color}`"></i>
        {{ option.name }}
      </template>
      <template v-slot:singlelabel="{ value }">
        <div class="multiselect-single-label">
          <i class="fa-fw me-1" :class="value.icon"
             :style="`color: ${setupStore.getClientEventTypeById(value.id)?.color}`"></i>
          {{ value.name }}
        </div>
      </template>
      <template v-slot:multiplelabel="{ values }">
        <div class="multiselect-multiple-label flex-wrap">
          <template v-for="value in values" :key="value.id">
            <div class="me-3 px-2 py-1 rounded text-white"
                 :style="`background-color: ${setupStore.getClientEventTypeById(value.id)?.color}`">
              <i class="fa-fw me-1" :class="value.icon"></i>
              {{ value.name }}
            </div>
          </template>
        </div>
      </template>
    </Multiselect>
<!--  <div>-->
<!--    {{ setupStore.clientEventTypes.length }}-->
<!--  </div>-->
</template>

<script>

import {useSetupStore} from "@/stores/setup";
import Multiselect from "@vueform/multiselect/src/Multiselect.vue";

export default {
  name: 'ClientEventTypeSelect2',
  components: {Multiselect},
  props: {
    multiple: {
      type: Boolean,
      default: () => false
    },
  },
  setup() {
    return {
      setupStore: useSetupStore(),
    }
  },
  data() {
    return {}
  },
  created() {
    this.setupStore.getClientEventTypes()
  },
  computed: {
    currentIds: {
      get() {
        return this.modelValue
      },
      set(values) {
        this.$emit('update:modelValue', values || [])
      },
    }
  },
  methods: {},
}
</script>

<style lang="scss">
</style>