<template>
  <div class="card mb-4">
    <div class="card-header">
      {{ $t('invoices.invoice') }} {{ invoice.number }} {{ invoice.name }}

      <router-link class="ms-2" :class="{'text-warning': invoice.number}"
                   :to="{name: ROUTES.INVOICES.EDIT, params: {id: invoice.id}}"
                   :title="$t('invoices.edit.edit')">
        <i class="fas fa-edit"/>
      </router-link>
    </div>
    <div class="card-body">
      <div class="client-data mt-4">
        <!--          {{ invoice }}-->
        <div class="float-end">
          <button class="btn btn-primary btn-sm me-2"
                  @click="dunningStore.pdf({id: invoice?.id, number: invoice?.id})">
            <i class="fa-fw fa-solid fa-file-pdf"></i>
            {{ $t('invoices.download') }}
          </button>

          <template v-if="renderable">
            <input type="checkbox" class="btn-check" id="btn-check" autocomplete="off"
                   :value="true"
                   v-model="showPreviewValue"
            >
            <label class="btn btn-primary btn-sm" for="btn-check">
              <i class="fa-fw fa-solid" :class="showPreview ? 'fa-eye' : 'fa-eye-slash'"></i>
              {{ $t('invoices.preview') }}
            </label>
          </template>

          <div class="mt-2" v-if="userStore.user.hasPermission(PERMISSIONS.ACCOUNTS.SHOW)">
            <router-link class="btn btn-info btn-sm d-block"
                         name="accounts.show"
                         target="_blank"
                         :to="{name: ROUTES.ACCOUNTS.SHOW, params: {id: 1}, query: {remittance_info: invoice.number}}">
              <i class="fa-fw fa-solid fa-piggy-bank"></i>
              {{ $t('accounts.show') }} {{ invoice.number }}
            </router-link>

            <router-link class="btn btn-info btn-sm d-block mt-2"
                         name="accounts.show"
                         target="_blank"
                         :to="{name: ROUTES.ACCOUNTS.SHOW, params: {id: 1}, query: {remittance_info: clientStore.client.kdnr}}">
              <i class="fa-fw fa-solid fa-piggy-bank"></i>
              {{ $t('accounts.show') }} {{ clientStore.client.kdnr }}
            </router-link>
          </div>
        </div>

        <h5>{{ $t('clients.client').capitalize() }}: {{ invoice.data.client.name }}</h5>
        <ul class="list-unstyled">
          <li><b>{{ $t('clients.email') }}:</b> {{ invoice.data.client.email }}</li>
        </ul>
        <table>
          <tbody>
          <tr>
            <th>{{ $t('invoices.payment_method') }}:</th>
            <td>
              {{ paymentMethod?.name }}
            </td>
            <td></td>
          </tr>
          <tr>
            <th>{{ $t('invoices.net_total') }}:</th>
            <td>
              {{ formatCurrency(invoice.net_total) }}
            </td>
            <td></td>
          </tr>
          <tr>
            <th>{{ $t('invoices.gross_total') }}:</th>
            <td>
              {{ formatCurrency(invoice.gross_total) }}
            </td>
            <td></td>
          </tr>

          <tr>
            <th>{{ $t('invoices.status') }}:</th>
            <td>
              {{ invoiceStatus?.name }}
            </td>
            <td></td>
          </tr>

          <tr>
            <th>{{ $t('invoices.date') }}:</th>
            <td>
              <template v-if="invoice.invoice_date">
                {{ formatDate(invoice.invoice_date, 'short2') }}
                ({{ $d(invoice.invoice_date, 'wday') }})
              </template>
            </td>
            <td><i class="fa-fw fa-solid fa-receipt"></i></td>
          </tr>

          <tr>
            <td colspan="4">
              <hr>
            </td>
          </tr>
          </tbody>
        </table>
        <table>
          <tbody>
          <tr>
            <th>&nbsp;</th>
            <th><i class="fa-solid fa-fw fa-envelope-circle-check"></i> {{ $t('invoices.sent_at') }}</th>
            <th><i class="fa-solid fa-fw fa-calendar-day"></i> {{ $t('invoices.due_date') }}</th>
            <th>&nbsp;</th>
          </tr>
          <tr>
            <th class="pe-3">
              {{ $t('invoices.invoice') }}:
            </th>
            <td class="pe-3">
              <template v-if="invoice.sent_at">
                {{ $d(invoice.sent_at, 'short2') }}
                ({{ $d(invoice.sent_at, 'wday') }})
              </template>
              <template v-else>
                -
              </template>
            </td>
            <td class="pe-3">
              {{ formatDate(invoice.due_date, 'short2') }}
              ({{ formatDate(invoice.due_date, 'wday') }})
            </td>
            <td>
              <template v-if="renderable">
                <button class="btn btn-sm btn-outline-secondary me-1" @click="preview" title="Preview Invoice-PDF">
                  <!-- FIXME: i18n -->
                  <i class="fa-fw fa-solid fa-file-pdf"></i>
                </button>
                <button class="btn btn-sm btn-outline-secondary" @click="showResend" title="Open (re)send dialog">
                  <!-- FIXME: i18n -->
                  <i class="fa-fw fa-solid fa-paper-plane"></i>
                </button>
              </template>
            </td>
          </tr>

          <template v-if="showStateAll || showStateReminder1">
            <tr>
              <th class="pe-3"> {{ $t('invoices.reminder1') }}:</th>
              <td>
                <template v-if="invoice.reminder1_at">
                  {{ formatDate(invoice.reminder1_at, 'short2') }}
                  ({{ formatDate(invoice.reminder1_at, 'wday') }})
                </template>
              </td>
              <td>
                <template v-if="reminder1DueDate">
                  {{ formatDate(reminder1DueDate, 'short2') }}
                  ({{ formatDate(reminder1DueDate, 'wday') }})
                </template>
              </td>
              <td>
                <button class="btn btn-sm btn-outline-secondary me-1" @click="preview('reminder1')"
                        title="Preview Reminder1-PDF"> <!-- FIXME: i18n -->
                  <i class="fa-fw fa-solid fa-file-pdf"></i>
                </button>
              </td>
            </tr>
          </template>

          <template v-if="showStateAll || showStateReminder2">
            <tr>
              <th class="pe-3">{{ $t('invoices.reminder2') }}:</th>
              <td>
                <template v-if="invoice.reminder2_at">
                  {{ formatDate(invoice.reminder2_at, 'short2') }}
                  ({{ formatDate(invoice.reminder2_at, 'wday') }})
                </template>
              </td>
              <td>
                <template v-if="reminder2DueDate">
                  {{ formatDate(reminder2DueDate, 'short2') }}
                  ({{ formatDate(reminder2DueDate, 'wday') }})
                </template>
              </td>
              <td>
                <button class="btn btn-sm btn-outline-secondary me-1" @click="preview('reminder2')"
                        title="Preview Reminder1-PDF"> <!-- FIXME: i18n -->
                  <i class="fa-fw fa-solid fa-file-pdf"></i>
                </button>
              </td>
            </tr>
          </template>

          <template v-if="showStateAll || showStateSourceOut">
            <tr>
              <td colspan="4">
                <hr>
              </td>
            </tr>

            <tr>
              <th>{{ $t('invoices.source_out') }}:</th>
              <td>{{
                  invoice.source_out ? formatDate(invoice.reminder1_at, 'short2') : '-'
                }}
              </td>
              <td><i class="fa-solid fa-fw"
                     :class="invoice.source_out ? 'fa-envelope-circle-check' : '' "></i></td>
            </tr>
          </template>

          <tr>
            <td colspan="4">
              <hr>
            </td>
          </tr>

          <!--          <tr v-if="currentDueDate">-->
          <!--            <th>{{ $t('invoices.current_due_date') }}:</th>-->
          <!--            <td>{{ formatDate(currentDueDate, 'short2') }}</td>-->
          <!--            <td><i class="fa-solid fa-fw fa-calendar-day"></i></td>-->
          <!--          </tr>-->
          <tr v-if="overDue" :class="overDue > 0 ? 'bg-warning' : ''">
            <th>{{ $t('invoices.over_due') }}:</th>
            <td>{{ overDue }} {{ $tc('day', overDue) }}</td>
            <td><i class="fa-solid fa-fw"
                   :class="overDue <= 0 ? 'fa-hourglass' : 'fa-triangle-exclamation' "></i></td>
          </tr>
          <tr>
            <th>{{ $t('invoices.statuses.paid') }}:</th>
            <td>{{
                invoice.date_of_payment ? formatDate(invoice.date_of_payment, 'short2') : '-'
              }}
            </td>
            <td>
              <i class="fa-regular fa-fw"
                 :class="invoice.date_of_payment ? 'fa-check-circle text-success' : '' "></i>
            </td>
          </tr>
          </tbody>
        </table>

        <div class="mt-4">
          <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
            <input type="radio" class="btn-check" id="btn-proceed-wait" autocomplete="off" v-model="proceedWith"
                   value="">
            <label class="btn btn-secondary" for="btn-proceed-wait">{{ $t('invoices.wait') }}</label>

            <input type="radio" class="btn-check" id="btn-proceed-due" autocomplete="off" v-model="proceedWith"
                   value="due">
            <label class="btn btn-primary" for="btn-proceed-due">{{ $t('invoices.proceed') }}</label>

            <input type="radio" class="btn-check" id="btn-proceed-paid" autocomplete="off" v-model="proceedWith"
                   value="paid">
            <label class="btn btn-warning" for="btn-proceed-paid">{{ $t('invoices.set_paid') }}</label>
          </div>
        </div>

        <Form @submit="proceedInvoice" autocomplete="off" v-if="proceedWith === 'due'" :validation-schema="schemaProceedInvoice">
          <div class="form-row">
            <div class="form-check" v-if="showProceedAll || newStatus === INVOICE_STATUS.SENT">
              <input class="form-check-input" type="radio" name="type" id="type1" :value="INVOICE_STATUS.SENT"
                     :disabled="invoice.sent_at"
                     v-model="newStatus" required aria-required="true"
                     @click="updateDates(invoice.due)">
              <label class="form-check-label d-block" for="type1">
                {{ $t('invoices.send_invoice') }} ({{ invoice.due }}
                {{ $tc('day', invoice.due) }})
              </label>
            </div>
            <div class="form-check" v-if="showProceedAll || newStatus === INVOICE_STATUS.RESEND">
              <input class="form-check-input" type="radio" name="type" id="type_resend"
                     :value="INVOICE_STATUS.RESEND"
                     :disabled="!invoice.invoice_date"
                     v-model="newStatus" required aria-required="true"
              >
              <label class="form-check-label d-block" for="type_resend">{{ $t('invoices.resend_invoice') }}</label>
            </div>
            <div class="form-check" v-if="showProceedAll || newStatus === INVOICE_STATUS.REMINDED1">
              <input class="form-check-input d-block" type="radio" name="type" id="type2"
                     :value="INVOICE_STATUS.REMINDED1"
                     v-model="newStatus" required aria-required="true"
                     @click="updateDates(invoice.reminder1_due)">
              <label class="form-check-label d-block" for="type2">
                {{ $t('invoices.reminder1') }} ({{ invoice.reminder1_due }}
                {{ $tc('day', invoice.reminder1_due) }})
              </label>
            </div>
            <div class="form-check" v-if="showProceedAll || newStatus === INVOICE_STATUS.REMINDED2">
              <input class="form-check-input" type="radio" name="type" id="type3"
                     :value="INVOICE_STATUS.REMINDED2"
                     v-model="newStatus" required aria-required="true"
                     @click="updateDates(this.currentDue=invoice.reminder2_due)">
              <label class="form-check-label d-block" for="type3">
                {{ $t('invoices.reminder2') }} ({{ invoice.reminder2_due }}
                {{ $tc('day', invoice.reminder2_due) }})
              </label>
            </div>
            <div class="form-check" v-if="showProceedAll || newStatus === INVOICE_STATUS.OUTSOURCED">
              <input class="form-check-input d-block" type="radio" name="type" id="type4"
                     :value="INVOICE_STATUS.OUTSOURCED"
                     v-model="newStatus" required aria-required="true">
              <label class="form-check-label d-block" for="type4">
                {{ $t('invoices.source_out') }}
              </label>
            </div>
          </div>

          <template v-if="newStatus">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-floating mt-1">
                  <input id="due" class="form-control" type="number" v-model="currentDue" required min="0"
                         @change="updateDates">
                  <label for="due">
                    {{ $t('invoices.due_in_days') }}
                  </label>
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-floating mt-1">
                  <input type="date" id="due_date" class="form-control" v-model="newDueDate" :readonly="true"/>
                  <label for="due_date">
                    {{ $t('invoices.due_date') }}
                  </label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-floating mt-1">
                  <input id="due" class="form-control" type="number" v-model="resubmissionDays" required min="0"
                         @change="updateResubmissionDate">
                  <label for="due">
                    {{ $t('invoices.resubmission_in_days') }}
                  </label>
                </div>

              </div>
              <div class="col-12 col-md-6 mt-1">
                <div class="form-floating">
                  <Field id="resubmissionDate" class="form-control" name="resubmissionDate" type="date"
                         v-model="resubmissionDate"/>
                  <label for="resubmissionDate">
                    {{ $t('invoices.resubmission_date') }}
                  </label>
                </div>
              </div>
            </div>

            <div class="form-row mt-2">
              <div class="btn-group" data-toggle="buttons">
                <Field type="radio" name="deliveryMethodKey" id="dm_email" class="btn-check"
                       value="email" v-model="deliveryMethodKey" required />
                <label for="dm_email" class="btn btn-outline-primary">
                  {{ $t('invoices.via_email') }}
                  <i class="fa-fw fa-solid fa-at"></i>
                </label>

                <Field type="radio" name="deliveryMethodKey" id="dm_mail" class="btn-check"
                       value="mail" v-model="deliveryMethodKey" />
                <label for="dm_mail" class="btn btn-outline-primary">
                  {{ $t('invoices.via_mail') }}
                  <i class="fa-fw fa-solid fa-envelope"></i>
                </label>

                <Field type="radio" name="deliveryMethodKey" id="dm_chargeable_mail" class="btn-check"
                       value="mail_with_costs" v-model="deliveryMethodKey" />
                <label for="dm_chargeable_mail" class="btn btn-outline-primary">
                  {{ $t('invoices.via_chargeable_mail') }}
                  <i class="fa-fw fa-solid fa-envelope"></i>
                </label>
              </div>

              <div class="form-floating mt-2" v-if="deliveryMethodKey==='email'">
                <input id="email" class="form-control" type="email" v-model="currentEmail" required>
                <label for="email">
                  {{ $t('invoices.current_email') }}
                </label>
              </div>
            </div>

            <button type="submit" class="btn btn-primary mt-2">
              <i class="fa-solid fa-paper-plane"></i>
              {{ $t('invoices.send') }}
            </button>

          </template>
        </Form>

        <Form @submit="proceedInvoicePaid" autocomplete="off" v-if="proceedWith === 'paid'">
          <div class="form-row">
            <div class="col-12 col-md-6">
              <div class="form-floating mt-2">
                <Field id="paymentDate" class="form-control" name="paymentDate" type="date"
                       v-model="dateOfPayment" aria-required="true" required/>
                <label for="paymentDate">
                  {{ $t('invoices.payment_date') }}
                </label>
              </div>
              <div class="btn-group" role="group" aria-label="">
                <button type="button" class="btn btn-sm btn-secondary" @click="setPaymentDate(-2)">vorgestern
                </button>
                <button type="button" class="btn btn-sm btn-secondary" @click="setPaymentDate(-1)">gestern</button>
                <button type="button" class="btn btn-sm btn-secondary" @click="setPaymentDate(0)">heute</button>
              </div>

              <div class="form-floating mt-2" v-if="false">
                <Field id="resubmissionDate" class="form-control" name="resubmissionDate" type="date"
                       v-model="resubmissionDate"/>
                <label for="resubmissionDate">
                  {{ $t('invoices.resubmission_date') }}
                </label>
              </div>
            </div>
          </div>

          <button type="submit" class="btn btn-primary mt-2">
            <i class="fa-solid fa-floppy-disk"></i>
            {{ $t('invoices.set_paid') }}
          </button>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>

import {useClientStore} from "@/stores/client";
import {useDunningStore} from "@/stores/dunning";
import {INVOICE_STATUS, PERMISSIONS, ROUTES} from "@/scripts/constants";
import {Field, Form} from 'vee-validate';
import dayjs from "dayjs";
import {Helpers} from "@/helpers";
import {useSetupStore} from "@/stores/setup";
import {useUserStore} from "@/stores/user";
import * as Yup from "yup";

export default {
  components: {
    Field,
    Form
  },
  setup() {
    return {
      clientStore: useClientStore(),
      dunningStore: useDunningStore(),
      setupStore: useSetupStore(),
      userStore: useUserStore(),
    }
  },
  watch: {
    paymentMethod(newPaymentMethod) {
      this.initResubmissionDays(newPaymentMethod)
    },
    newDueDate() {
      this.updateResubmissionDate()
    },
  },
  props: {
    showPreview: {
      type: Boolean,
    },
    invoiceModel: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  data() {
    return {
      dateOfPayment: null,
      proceedWith: '',
      showProceedAll: true,
      deliveryMethodKey: null,
      newStatus: null,
      currentEmail: null,
      currentDue: null,
      resubmissionDays: null, // set by watcher after paymentMethod available
      resubmissionDate: '',
      showStateAll: false, // currently debug only
      schemaProceedInvoice: Yup.object().shape({
        // FIXME: require info OR file
        deliveryMethodKey: Yup.string().required(this.$t('validation.required')),
        // file: Yup.mixed().required('validation.required'), // hat noch nicht funktioniert
      }),
    }
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS
    },
    ROUTES() {
      return ROUTES
    },
    invoiceId() {
      return this.$route.params.invoice_id;
    },
    invoice: {
      get() {
        return this.invoiceModel
      },
      set(newValue) {
        this.$emit('update:invoiceModel', newValue)
      }
    },
    newDueDate() {
      if (this.invoice.number && this.newStatus === INVOICE_STATUS.RESEND) {
        // resend -> keep old dueDate
        return this.invoiceDueDate
      } else {
        return Helpers.formatDateTech(dayjs().add(this.currentDue, 'day'))
      }
    },
    invoiceDueDate() {
      return this.invoice.due_date
    },
    reminder1DueDate() {
      return this.calcDate(this.invoice.reminder1_at, this.invoice.reminder1_due)
    },
    reminder2DueDate() {
      return this.calcDate(this.invoice.reminder2_at, this.invoice.reminder2_due)
    },
    // currentDue() {
    //   this.currentDueFieldValue || 14
    // },
    paymentMethod() {
      return this.invoice?.payment_method_id ? this.setupStore.getPaymentMethodById(this.invoice.payment_method_id) : null
    },
    currentDueDate() {
      return this.reminder2DueDate || this.reminder1DueDate || this.invoiceDueDate
    },
    invoiceStatus() {
      return this.setupStore.getInvoiceStatusById(this.invoice.invoice_status_id)
    },
    overDue() {
      if (!this.invoiceStatus) {
        return
      }
      if (this.invoiceStatus.key === INVOICE_STATUS.PAID || this.invoiceStatus.key === INVOICE_STATUS.CANCELED) {
        return
      }
      const d = this.currentDueDate
      const days = d ? dayjs().diff(d, 'day') : null;
      // return days && days > 0 ? days : null;
      return days
    },
    showStateReminder1() {
      return this.invoice.reminder1_at
    },
    showStateReminder2() {
      return this.invoice.reminder2_at
    },
    showStateSourceOut() {
      // FIXME
      return false;
    },
    showPreviewValue: {
      get() {
        return this.showPreview;
      },
      set(val) {
        this.$emit('update:showPreview', val);
      }
    },
    renderable() {
      return !this.invoice.number || this.invoice.has_pdf
    },
  },
  created() {
    this.setupStore.getInvoiceStatuses()
    this.setupStore.getPaymentMethods()
    this.INVOICE_STATUS = INVOICE_STATUS
    this.currentEmail = this.invoice.data.client.email // FIXME: old e-mail?
    this.deliveryMethodKey = this.invoice.invoice_delivery_method?.key
    this.dateOfPayment = this.invoice.date_of_payment
    this.initResubmissionDays(this.paymentMethod)
  },
  methods: {
    initResubmissionDays(paymentMethod) {
      if (paymentMethod && this.resubmissionDays === null) {
        this.resubmissionDays = paymentMethod.group === 'sepa_direct_debit' ? 1 : 2
      }
    },
    updateDates(currentDue) {
      if (typeof currentDue === 'number') {
        this.currentDue = currentDue
      }
      this.updateResubmissionDate()
    },
    updateResubmissionDate() {
      this.resubmissionDate = Helpers.formatDateTech(dayjs(this.newDueDate).add(this.resubmissionDays, 'day'))
    },
    proceedInvoice() {
      const payload = {
        new_status: this.newStatus,
        delivery_method_key: this.deliveryMethodKey,
        email: this.currentEmail,
        current_due: this.currentDue,
        resubmission_date: this.resubmissionDate,
      }
      this.dunningStore.proceedInvoice(this.invoiceId, payload).then(() => {
        this.$emit('updated')
        this.$emit('success', this.$t('invoice.proceeded'))
      })
    },
    proceedInvoicePaid() {
      const payload = {
        invoice: {
          date_of_payment: this.dateOfPayment,
        }
      }
      this.dunningStore.setInvoicePaid(this.invoiceId, payload).then(() => {
        this.$emit('updated')
      })
    },
    setPaymentDate(days) {
      this.dateOfPayment = Helpers.formatDateTech(dayjs().add(days, 'day'))
    },
    showResend() {
      this.proceedWith = 'due'
      this.newStatus = INVOICE_STATUS.SENT
      this.updateDates(this.invoice.due)
    },
    preview(pdf) {
      this.showPreviewValue = true
      this.dunningStore.preview(this.invoiceId, pdf)
    },
    calcDate(date, days) {
      if (!date) {
        return
      }
      const newDate = dayjs(date).add(days, 'day')
      return Helpers.formatDateTech(newDate)
    }
  },
}
</script>
