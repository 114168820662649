<template>
  <div class="row">
    <div class="modal fade p-0" id="receiptsModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{$t('accounts.search_receipts')}}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <DataView :total-records="this.receiptStore.itemLength" :value="receipts" layout="grid" :paginator="true" :rows="9" :lazy="true" @page="onPage($event)">
                <template #header>
                    <div class="d-flex">
                        <span class="me-2">Filter</span>
                        <div class="col-12 col-lg-4">
                          <span class="badge interactive me-2"
                                :class="{'bg-dark': !activeFilters.includes(filter), 'bg-success': activeFilters.includes(filter)}"
                                :key="index" v-for="(filter, index) in filters"
                                @click="toggleFilter(filter)">
                                {{ filter.name }}
                          </span>
                        </div>
                    </div>
                </template>
              <template #grid="{items}">
                <div class="row">
                  <div v-for="(item, index) in items" :key="index" class="col-12 col-lg-4 mb-3">
                    <DataViewContent :data="item" :matchable="true" @entitySelected="receiptSelected"/>
                  </div>
                </div>
              </template>
            </DataView>
          </div>
          <div class="modal-footer">
            <button @click="assignReceipt" type="button" class="btn btn-primary" :class="{disabled: currentSelectedReceipt === null}" data-bs-dismiss="modal">{{$t('receipts.assign')}}</button>
            <button @click="closeModal" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useReceiptStore} from "@/stores/receipt";
import {useTransactionStore} from "@/stores/transactions";
import {Helpers} from "@/helpers";
import DataView from "primevue/dataview";
import DataViewContent from "@/pages/receipts/components/DataViewContent.vue";
export default {
  name: 'ModalReceipts',
  components: {
    DataView,
    DataViewContent
  },
  setup() {
    return {
      receiptStore: useReceiptStore(),
      transactionStore: useTransactionStore()
    }
  },
  data() {
    return {
      transactionId: null,
      currentSelectedReceipt: null,
      filters: [
          {key: 'assigned', name: this.$t('receipts.is_assigned')},
          {key: 'not_assigned', name: this.$t('receipts.is_not_assigned')},
      ],
      activeFilters: [
      ],
    }
  },
  computed: {
    receipts() {
      let parsedReceipts = []
      this.receiptStore.receipts.forEach((receipt) => {
        if(this.currentSelectedReceipt === receipt.id) {
          receipt.selected = true
        }
        parsedReceipts.push(receipt)
      })
      return parsedReceipts
    }
  },

  created() {
    Helpers.emitter.on('openReceiptsModal', (transactionId) => {
      this.transactionId = transactionId
      this.activeFilters = [this.filters[1]]
      this.getReceipts()
    })
  },
  methods: {
    toggleFilter(filter) {
        if (this.activeFilters.find(element => element.key === filter.key)) {
            this.activeFilters = this.activeFilters.filter(item => item.key !== filter.key)
        } else {
            this.activeFilters.push(filter)
        }
        this.getReceipts(this.activeFilters)
    },
    getReceipts() {
      this.receiptStore.index(this.activeFilters)
    },
    onPage(event) {
      this.receiptStore.serverOptions = event
      this.getReceipts()
    },
    receiptSelected(id) {
      this.receiptStore.receipts.forEach((receipt) => {
          receipt.selected = false
      })
      this.currentSelectedReceipt = id
    },
    assignReceipt() {
      this.receiptStore.update(this.currentSelectedReceipt, this.transactionId).then(({data}) => {
        this.transactionStore.getForAccount(data.account_id)
        Helpers.emitter.emit('success', data.message)
      }).catch(({data}) => {
        this.transactionStore.getForAccount(data.account_id)
        Helpers.emitter.emit('error', data.message)
      })
      this.currentSelectedReceipt = null
    },
    closeModal() {
      this.currentSelectedReceipt = null
    }
  },
  watch: {
  }
}
</script>
