<template>
  <div class="row">
    <div class="col-12">
      <h2>{{ $t('product_billing_periods.edit') }}</h2>
    </div>
    <div class="col-12 mt-3" v-if="billingPeriod">
      <div class="d-flex">
        <h4>{{ $t('products.product') }}: {{ billingPeriod.product.auto_name }} </h4>
        <div class="ms-3">
          <div class="d-flex">
            <router-link :to="{name: ROUTES.PRODUCTS.SHOW, params: {product_id: billingPeriod.product.id }}"
                         class="btn btn-primary btn-sm">
              <i class="fas fa-eye fa-fw"/>
            </router-link>
            <router-link :to="{name: ROUTES.PRODUCTS.EDIT, params: {id: billingPeriod.product.id }}"
                         class="btn btn-primary btn-sm ms-1">
              <i class="fas fa-edit fa-fw"/>
            </router-link>
            <button @click="cloneBillingPeriod" type="button"
                    :title="$t('actions.clone')"
                    class="btn btn-secondary btn-sm ms-1">
              <i class="fa-solid fa-clone fa-fw"/>
            </button>
            <button @click="deleteBillingPeriod" type="button"
                    class="btn btn-outline-danger btn-sm ms-1">
              <i class="fas fa-trash fa-fw"/>
            </button>
          </div>
        </div>
      </div>
    </div>
    <Form class="row" v-slot="{errors}" autocomplete="off" @submit="updateProductBillingPeriod" v-if="billingPeriod"
          :validation-schema="schema" :initial-values="{product_billing_period: billingPeriod}">
      <div class="row">
        <div class="col-6 col-lg-2 mb-3">
          <div class="form-floating">
            <Field id="product_billing_period.start_on" name="product_billing_period.start_on"
                   type="date"
                   class="form-control"
                   :class="{'is-invalid': errors['product_billing_period.start_on']}"/>
            <label for="product_billing_period.start_on">{{ $t('product_billing_periods.start_on') }}</label>
            <div v-if="errors['product_billing_period.start_on']" class="invalid-feedback">
              {{ errors['product_billing_period.start_on'] }}
            </div>
          </div>
        </div>
        <div class="col-6 col-lg-2 mb-3">
          <div class="form-floating">
            <Field id="product_billing_period.end_on" name="product_billing_period.end_on"
                   type="date"
                   class="form-control"
                   :class="{'is-invalid': errors['product_billing_period.end_on']}"/>
            <label for="product_billing_period.end_on">{{ $t('product_billing_periods.end_on') }}</label>
            <div v-if="errors['product_billing_period.end_on']" class="invalid-feedback">
              {{ errors['product_billing_period.end_on'] }}
            </div>
          </div>
        </div>
        <div class="col-6 col-lg-2 col-xl-1 mb-3">
          <div class="form-floating">
            <Field id="product_billing_period.price" name="product_billing_period.price"
                   type="number"
                   step="0.1"
                   class="form-control"
                   :class="{'is-invalid': errors['product_billing_period.price']}"/>
            <label for="product_billing_period.price">{{ $t('product_billing_periods.price') }}</label>
            <div v-if="errors['product_billing_period.price']" class="invalid-feedback">
              {{ errors['product_billing_period.price'] }}
            </div>
          </div>
        </div>
        <!-- Steuer -->
        <div class="col-6 col-lg-2 col-xl-1 mb-3">
          <div class="form-floating">
            <Field
                id="vat"
                name="product_billing_period.vat"
                type="number"
                step="0.1"
                value="19"
                required
                class="form-control" :class="{'is-invalid': errors[`product_billing_period.vat`]}"/>
            <label for="vat">{{ $t('products.vat') }}</label>
            <div v-if="errors[`product_billing_period.vat`]" class="invalid-feedback">
              {{ errors[`product_billing_period.vat`] }}
            </div>
          </div>
        </div>

        <div class="w-100"></div>

        <div class="col-12 col-lg-3 mb-3">
          <div class="form-floating">
            <Field name="product_billing_period.product_billing_period_status_id"
                   :class="{'is-invalid': errors[`invoice.payment_method_id`]}"
                   class="form-select" as="select" id="product_billing_period.product_billing_period_status_id">
              <option v-for="(productBillingPeriodStatus, key) in setupStore.productBillingPeriodStatuses" :key="key"
                      :value="productBillingPeriodStatus.id">
                {{ productBillingPeriodStatus.name }}
              </option>
            </Field>
            <label for="product_billing_period.product_billing_period_status_id">
              {{ $t('product_billing_periods.product_billing_period_status') }}
            </label>
            <div v-if="errors['product_billing_period.product_billing_period_status_id']" class="invalid-feedback">
              {{ errors['product_billing_period.product_billing_period_status_id'] }}
            </div>
          </div>
        </div>
        <div class="col-6 col-lg-2 mb-3">
          <div class="form-floating">
            <Field id="product_billing_period.scheduled_billing_date"
                   name="product_billing_period.scheduled_billing_date"
                   type="date"
                   class="form-control"
                   :class="{'is-invalid': errors['product_billing_period.scheduled_billing_date']}"/>
            <label for="product_billing_period.scheduled_billing_date">{{
                $t('product_billing_periods.scheduled_billing_date')
              }}</label>
            <div v-if="errors['product_billing_period.scheduled_billing_date']" class="invalid-feedback">
              {{ errors['product_billing_period.scheduled_billing_date'] }}
            </div>
          </div>
        </div>

        <div class="col-6 col-lg-2 col-xl-1 mb-3">
          <div class="form-floating">
            <Field
                id="installment"
                name="product_billing_period.installment"
                type="number"
                step="1"
                class="form-control" :class="{'is-invalid': errors[`product_billing_period.installment`]}"/>
            <label for="installment">{{ $t('product_billing_periods.installment') }}</label>
            <div v-if="errors[`product_billing_period.installment`]" class="invalid-feedback">
              {{ errors[`product_billing_period.installment`] }}
            </div>
          </div>
        </div>

        <div class="col-6 col-lg-2 col-xl-1 mb-3">
          <div class="form-floating">
            <Field
                id="number_of_installments"
                name="product_billing_period.number_of_installments"
                type="number"
                step="1"
                class="form-control" :class="{'is-invalid': errors[`product_billing_period.number_of_installments`]}"/>
            <label for="number_of_installments">{{
                $t('product_billing_periods.number_of_installments')
              }}</label>
            <div v-if="errors[`product_billing_period.number_of_installments`]" class="invalid-feedback">
              {{ errors[`product_billing_period.number_of_installments`] }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 col-lg-4 col-xl-2 mb-3">
        <div class="form-floating">
          <Field
              id="new_number_of_installments"
              name="product_billing_period.new_number_of_installments"
              type="number"
              step="1"
              class="form-control"
              :class="{'is-invalid': errors[`product_billing_period.new_number_of_installments`]}"/>
          <label for="new_number_of_installments">{{
              $t('product_billing_periods.new_number_of_installments')
            }}</label>
          <div v-if="errors[`product_billing_period.new_number_of_installments`]" class="invalid-feedback">
            {{ errors[`product_billing_period.new_number_of_installments`] }}
          </div>
        </div>
      </div>

      <template v-if="userStore.user.hasPermission(PERMISSIONS.PRODUCTS.ADMIN)">
        <CollapsedContent id="product-billing-period-special-fields">
          <template v-slot:action><i class="fa-solid" :class="classAdminIcon"></i></template>
          <div class="row">
            <div class="col-6 col-lg-2 col-xl-1 mb-3">
              <div class="form-floating">
                <Field
                    id="product_id"
                    name="product_billing_period.product_id"
                    type="number"
                    class="form-control" :class="{'is-invalid': errors[`product_billing_period.product_id`]}"/>
                <label for="product_id">{{ $t('product_id') }}</label>
                <div v-if="errors[`product_billing_period.product_id`]" class="invalid-feedback">
                  {{ errors[`product_billing_period.product_id`] }}
                </div>
              </div>
            </div>
            <div class="col-6 col-lg-2 col-xl-1 mb-3">
              <div class="form-floating">
                <Field
                    id="client_event_id"
                    name="product_billing_period.client_event_id"
                    type="number"
                    step="1"
                    class="form-control" :class="{'is-invalid': errors[`product_billing_period.client_event_id`]}"/>
                <label for="client_event_id">{{ $t('client_event_id') }}</label>
                <div v-if="errors[`product_billing_period.client_event_id`]" class="invalid-feedback">
                  {{ errors[`product_billing_period.client_event_id`] }}
                </div>
              </div>
            </div>

            <!-- Typ -->
            <div class="col-12 col-lg-4 mb-3">
              <div class="form-floating">
                <Field class="form-select"
                       as="select"
                       id="product_billing_period.product_type_id"
                       name="product_billing_period.product_type_id"
                       :class="{'is-invalid': errors[`product_billing_period.product_type_id`]}"
                >
                  <option v-for="(productType, key) in productTypes" :key="`${key}-${index}`"
                          :value="productType.id"
                  >
                    {{ productType.name }}
                  </option>
                </Field>
                <label :for="`product_type_id`">{{ $t('product_billing_period.product_type') }}</label>
                <div v-if="errors[`product_billing_period.product_type_id`]" class="invalid-feedback">
                  {{ errors[`product_billing_period.product_type_id`] }}
                </div>
              </div>
            </div>

          </div>
        </CollapsedContent>
      </template>

      <div class="row">
        <div class="col-12">
          <button class="btn btn-success" type="submit">
            {{ $t('save') }}
          </button>
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
import {useProductBillingPeriodStore} from "@/stores/product_billing_period";
import {Form, Field} from "vee-validate";
import * as Yup from "yup";
import {Helpers} from "@/helpers";
import {useSetupStore} from "@/stores/setup";
import {PERMISSIONS, ROUTES} from "@/scripts/constants";
import CollapsedContent from "@/components/CollapsedContent.vue";
import {useUserStore} from "@/stores/user";

export default {
  name: 'ProductBillingPeriodsEdit',
  setup() {
    return {
      productBillingPeriodStore: useProductBillingPeriodStore(),
      setupStore: useSetupStore(),
      userStore: useUserStore(),
    }
  },
  components: {
    CollapsedContent,
    Form,
    Field
  },
  data() {
    return {
      schema: Yup.object().shape({
        product_billing_period: Yup.object().shape({
          start_on: Yup.string().required(this.$t('validation.required')),
          end_on: Yup.string().required(this.$t('validation.required')),
          price: Yup.string().required(this.$t('validation.required')),
        }),
      }),
    }
  },
  created() {
    this.load(this.$route.params.id)
    console.log("created")
    this.setupStore.getProductBillingPeriodStatuses()
    this.setupStore.getProductTypes()
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS
    },
    ROUTES() {
      return ROUTES
    },
    billingPeriod() {
      return this.productBillingPeriodStore.product_billing_period
    },
    productTypes() {
      const currentlyIsProduct = this.isPartOfPackage()
      const allProductTypes = this.setupStore.productTypes
      // prevent type package inside package:
      const filteredProductTypes = allProductTypes.filter((pt) => !currentlyIsProduct || (currentlyIsProduct && !pt.package))
      return filteredProductTypes
    },
  },
  methods: {
    load(id) {
      this.productBillingPeriodStore.get(id, (data) => {
        this.setCurrentClientById(data.product?.client_id)
      })
    },
    isPartOfPackage() { // TODO: extract duplicate code to mixin/Composable
      // FIXME: detect part of package
      // const parent_id = this.fieldValue.parent_id
      // return !!parent_id
      return false;
    },
    updateProductBillingPeriod(data) {
      this.productBillingPeriodStore.update(this.$route.params.id, data).then(() => {
        Helpers.emitter.emit('success', this.$t('product_billing_periods.edit_success'))
      })
    },
    cloneBillingPeriod() {
      const id = this.$route.params.id
      this.productBillingPeriodStore.clone(id).then(({data}) => {
        Helpers.emitter.emit('success', this.$t('product_billing_periods.clone.success'))
        this.$router.push({name: ROUTES.PRODUCT_BILLING_PERIODS.EDIT, params: {id: data.id}})
        this.load(data.id)
      })
    },
    deleteBillingPeriod() {
      const id = this.$route.params.id
      this.productBillingPeriodStore.delete(id).then(() => {
        const product_id = this.billingPeriod.product?.id || this.billingPeriod.product_id
        if (!product_id) {
          throw new Error(`ProductBillingPeriod#${id} is missing product.id`)
        }
        Helpers.emitter.emit('success', this.$t('product_billing_periods.delete_success'))
        this.$router.push({name: ROUTES.PRODUCTS.SHOW, params: {product_id}})
      })
    },
  }
}
</script>
