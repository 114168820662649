<template>
  <div>
    <template v-if="data">
      <collapsed-content id="collapsed-info">
        <template v-slot:action>
          {{ $t('open') }}
        </template>
        <template v-slot>
          <table>
            <tbody>
            <template v-for="(value, key) in data" :key="key">
              <tr>
                <th class="pe-2">{{ key.replaceAll('__', ' > ').replaceAll('_', ' ') }}:</th>
                <td>{{ autoformat(value) }}</td>
              </tr>
            </template>
            </tbody>
          </table>
        </template>
      </collapsed-content>
    </template>
    <template v-else>
      {{ $t('none') }}
    </template>
  </div>
</template>

<script>


import CollapsedContent from "@/components/CollapsedContent.vue";

export default {
  name: 'CollapsedInfos',
  components: {
    CollapsedContent
  },
  props: {
    data: {
      type: Object,
    },
  },
  methods: {
    autoformat(value) {
      if (value?.match && value.match(/^\d{4}-\d\d-\d\d$/)) {
        return this.formatDate(value, 'short2')
      }
      return value
    }
  }
}
</script>