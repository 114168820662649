<template>
    <div class="card" :class="{selected: data.selected}" @click.prevent="selectInvoice(data.id)">
      <div class="card-header d-flex flex-column">
        <div>
          {{data.client_name}}
        </div>
        <div class="d-flex justify-content-between">
          <div>
            <b>{{data.number}}</b>
          </div>
          <span v-if="grossAmountMismatch" style="color: red;">
              <i class="fas fa-warning" /> {{ $t('invoices.amount_mismatch') }}
              {{ formatCurrency(data.gross_total) }}
          </span>
          <span class="badge" :class="{'bg-success': data.is_assigned, 'bg-danger': !data.is_assigned }">
            <template v-if="data.is_assigned">
              {{$t('receipts.is_assigned')}}
            </template>
            <template v-if="!data.is_assigned">
              {{$t('receipts.is_not_assigned')}}
            </template>
          </span>
        </div>
      </div>
      <div class="card-body">
        <template  v-if="data.pdf_url">
          <vue-pdf-embed :page="1" :source="data.pdf_url"/>
        </template>
        <template v-else>
          pdfUrl is missing
        </template>
      </div>
    </div>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed'

export default {
  name: 'DataViewContent',
  setup() {
    return {
    }
  },
  components: {
    VuePdfEmbed
  },
  props: {
    data: {
      type: Object,
    },
    transaction: {
        type: Object,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    grossAmountMismatch() {
        return this.data.gross_total !== this.transaction.raw_amount;
    }
  },
  created() {
  },
  methods: {
    selectInvoice(id) {
      this.$emit('entitySelected', id)
    }
  }
}
</script>
<style lang="scss">
.card {
  &.selected {
    border: 1px solid red;
  }
}
</style>