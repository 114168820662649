<template>
  <div>
    <div class="input-group">
            <span class="input-group-text">
                <i class="fa-thin fa-magnifying-glass-arrow-right"></i>
            </span>
      <div class="form-floating">
        <input type="text" class="form-control" id="fulltext-search-input"
               minlength="3"
               v-model="searchString"
               @keydown.esc.prevent="resetSearch"
               @keydown.enter="startSearch"
               @input="startSearch" ref="searchString">
        <label for="fulltext-search-input">{{ $t('dashboard.search_intentions') }}</label>
      </div>
      <span class="input-group-text" @click="resetSearch"><i class="fas fa-times"></i></span>
    </div>
    <div class="results row mt-4">
      <div class="col-12 col-xl-6" v-if="clientResults.length > 0">
        <h3>{{ $tc('clients.client', 2) }}</h3>
        <div class="list-group">
          <template :key="index" v-for="(client, index) in clientResults">
            <ClientButton :client="client" :search-string="searchString" />
          </template>
        </div>
      </div>
      <div class="col-12 col-xl-6 mt-2" v-if="contactResults.length > 0">
        <h3>{{ $tc('contacts.contact', 2) }}</h3>
        <div class="list-group">
          <template :key="index" v-for="(contact, index) in contactResults">
            <ContactButton :contact="contact" :search-string="searchString" />
          </template>
        </div>
      </div>
      <div class="col-12 col-xl-6 mt-2" v-if="invoiceResults.length > 0">
        <h3>{{ $tc('invoices.invoice', 2) }}</h3>
        <div class="list-group">
          <div class="list-group-item contact d-flex justify-content-between" :key="index"
               v-for="(invoice, index) in invoiceResults">
            <div>
              {{ invoice.client.kdnr }} {{ invoice.client.name }} &gt;
              <template v-if="invoice.number">
                <span v-html="checkForMarking(invoice.number)"/>
              </template>
              <template v-if="invoice.name">
                (<span v-html="checkForMarking(invoice.name)"/>)
              </template>
            </div>
            <div> <!-- prevent button from growing vertically if result is multiline -->
              <router-link class="ms-3 btn btn-primary btn-sm text-nowrap"
                           :to="{name: ROUTES.DUNNING.EDIT, params: {invoice_id: invoice.id}}">
                <i class="fa-solid fa-file-invoice-dollar"></i> {{ $t('invoices.dunning_edit') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useContactStore} from "@/stores/contact";
import {useClientStore} from "@/stores/client";
import {useInvoiceStore} from "@/stores/invoice";
import {ROUTES} from "@/scripts/constants";
import {useSetupStore} from "@/stores/setup";
import {Helpers} from "@/helpers";
import ContactButton from "@/pages/contacts/components/ContactButton.vue";
import ClientButton from "@/pages/clients/components/CiientButton.vue";

export default {
  name: 'FullTextSearch',
  components: {
    ClientButton,
    ContactButton,
  },
  setup() {
    return {
      contactStore: useContactStore(),
      clientStore: useClientStore(),
      invoiceStore: useInvoiceStore(),
      setupStore: useSetupStore(),
    }
  },
  props: {},
  data() {
    return {
      timer: null,
      searchString: null,
      contactResults: [],
      clientResults: [],
      invoiceResults: [],
    }
  },
  methods: {
    checkForMarking(text) {
      if (text) {
        const regex = new RegExp(this.searchString, 'gi');
        text = text.replace(/(<mark class="highlight">|<\/mark>)/gim, '');
        return text.replace(regex, '<mark class="highlight">$&</mark>');
      }
      return ''
    },
    async resetSearch() {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.contactResults = this.clientResults = this.invoiceResults = []
      this.searchString = null
      await Helpers.removeQueryFromUrl("s")
    },
    startSearch(e) {
      if (!e.target.value) {
        return
      }
      if (!e.target.reportValidity()) {
        return
      }
      this.searchString = e.target.value.replace(/^0+/, '');
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(async () => {
        await Helpers.setUrlQuery({s: this.searchString})
        this.contactStore.search(this.searchString).then((result) => {
          this.contactResults = result.data.contacts
        })
        this.clientStore.search(this.searchString).then((result) => {
          this.clientResults = result.data.clients
        })
        this.invoiceStore.search(this.searchString).then((result) => {
          this.invoiceResults = result.data.invoices
        })
      }, 500);
    }
  },
  created() {
    if (this.$route.query.s) {
      this.searchString = this.$route.query.s;
      this.$nextTick(() => {
        this.$refs.searchString.dispatchEvent(new Event('input'))
      })
    }
  },
  mounted() {
    this.$refs.searchString.focus()       // sometime this doesn't work.
  },
  computed: {
    ROUTES() {
      return ROUTES
    }
  },
}
</script>
<style>
.highlight {
  background-color: #EEF43B;
  padding: 0;
}
</style>