<template>
  <div class="row">
    <div class="col-12">
      <h2>{{ $tc('product_types.type', 2).capitalize() }}</h2>
    </div>
    <div class="col-5">
      <table>
        <thead>
        <tr>
          <th>{{ $t('product_types.group') }}</th>
          <th>{{ $t('product_types.name') }} / {{ $t('product_types.description') }}</th>
          <th>{{ $t('product_types.setup_price') }}</th>
          <th>{{ $t('product_types.list_price') }}</th>
          <th class="text-nowrap">{{ $t('product_types.duration_months') }}</th>
          <th>{{ $t('product_types.invoice_label') }}</th>
          <th>{{ $t('product_types.key') }}</th>
          <th>{{ $t('product_types.number_of_products') }}</th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(productType, key) in setupStore.productTypes" :key="key">
          <tr>
            <td class="text-nowrap pe-3">
              <i class="fa-solid fa-box-taped" v-if="productType.package"></i>
              {{ setupStore.getProductTypeCategoryById(productType.product_type_category_id)?.name }}
            </td>
            <td class="text-nowrap">
              {{ productType.name }}
              <small v-if="productType.description?.length" class="opacity-50"><br>{{ productType.description }}</small>
            </td>
            <td class="currency">{{ formatCurrency(productType.setup_price) }}</td>
            <td class="currency">{{ formatCurrency(productType.price) }}</td>
            <td class="text-end pe-3">{{ productType.duration_months }}</td>
            <td class="text-nowrap">
              <div v-if="productType.invoice_setup_label?.length">
                <span class="opacity-50">[{{ $t('product_types.invoice_label_context.setup') }}]</span>
                  {{ productType.invoice_setup_label }}
              </div>
              <div v-if="productType.invoice_label?.length">
                <span class="opacity-50">[{{ $t('product_types.invoice_label_context.recurring') }}]</span>
                  {{ productType.invoice_label }}
              </div>
            </td>
            <td><small>{{ productType.key }}</small></td>
            <td class="text-end pe-3"><small>{{ productType.product_cnt }}</small></td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {useSetupStore} from "@/stores/setup";

export default {
  name: 'ProductTypesIndex',
  components: {
  },
  setup() {
    return {
      setupStore: useSetupStore(),
    }
  },
  data() {
    return {
    }
  },
  created() {
    this.setupStore.getProductTypes()
    this.setupStore.getProductTypeCategories()
    this.setTitle(this.$tc('product_types.type', 2).capitalize())
  },
  methods: {
    openProductType(productType) {
      this.setupStore.pdf(productType)
    },
  },
  watch: {
  }
}
</script>
