<template>
    <Multiselect
        :mode="multiple ? 'multiple' : 'single'"
        :canDeselect="multiple"
        :canClear="multiple"
        :hideSelected="false"
        :options=users
        v-model="currentIds"
        valueProp="id"
        label="name"
        class="form-control"
        :searchable="true"
        :placeholder="short ? $tc('users.user', 1).slice(0, 2) + '…' : $tc('users.user', 1)"
    >
      <template v-slot:option="{ option }">
        <option :value="option.id">
          <UserAvatar :id="option.id" class="me-2"/>
          {{ option.name }}
        </option>
      </template>
      <template v-slot:singlelabel="{ value }">
        <div class="multiselect-single-label">
          <UserAvatar :id="value.id" class="me-2"/>
          <template v-unless="short">{{ value.name }}</template>
        </div>
      </template>
      <template v-slot:multiplelabel="{ values }">
        <div class="multiselect-multiple-label flex-wrap">
          <template v-for="value in values" :key="value.id">
            <div class="me-3 px-2 py-1 rounded">
              <UserAvatar :id="value.id" class="me-2"/>
              {{ value.name }}
            </div>
          </template>
        </div>
      </template>
    </Multiselect>
</template>

<script>

import Multiselect from "@vueform/multiselect/src/Multiselect.vue";
import {useAllUsersStore} from "@/stores/all_users";
import UserAvatar from "@/components/UserAvatar.vue";

export default {
  name: "UserSelect",
  components: {
    Multiselect,
    UserAvatar,
  },
  data() {
    return {
    }
  },
  props: {
    multiple: {
      type: Boolean,
      default: () => false
    },
    short: {
      type: Boolean,
      default: () => false
    },
  },
  setup() {
    return {
      allUsersStore: useAllUsersStore(),
    }
  },
  created() {
    this.allUsersStore.index()
    this.currentUserIds = this.userIds
  },
  computed: {
    users() {
      return this.allUsersStore.users;
    },
    currentIds: {
      get() {
        return this.modelValue
      },
      set(values) {
        this.$emit('update:modelValue', values || [])
      },
    }
  },
  methods: {}
}
</script>

<style lang="scss">
.multiselect-dropdown {
  right: unset !important;
  //overflow-x:visible;
}
</style>