<template>
  <div class="row">
    <div class="col-12 d-flex align-items-center">
      <h2 class="m-0">{{ $tc('clients.client', 2).capitalize() }}</h2>
      <div>
        <router-link class="ms-3 btn btn-primary btn-sm" :to="{query: {client_type_id: 1}, name: ROUTES.CLIENTS.INDEX}">
          <i class="fa-solid fa-users-between-lines"></i> {{ $tc('clients.lead', 2) }}
        </router-link>
      </div>
      <div>
        <router-link class="ms-3 btn btn-primary btn-sm" :to="{name: ROUTES.CLIENTS.CREATE}">
          <i class="fa-solid fa-circle-plus"></i> {{ $t('clients.create.headline') }}
        </router-link>
      </div>
    </div>
    <div class="col-12">
      <ClientsTable :paginator="true" :show-filters="true" :show-column-active="false" />
    </div>
  </div>
</template>

<script>

import {useClientStore} from "@/stores/client";
import ClientsTable from "@/components/tables/ClientsTable"
import {ROUTES} from "@/scripts/constants";

export default {
  name: 'ClientsIndex',
  computed: {
    ROUTES() {
      return ROUTES
    }
  },
  components: {
    ClientsTable,
  },
  setup() {
    return {
      clientStore: useClientStore(),
    }
  },
  data() {
    return {
    }
  },
  created() {
    this.setTitle(this.$tc('clients.client', 2).capitalize())
  },
  methods: {
  },
  watch: {
  }
}
</script>