<template>
    <div class="card" :class="{selected: data.selected}" @click.prevent="selectReceipt(data.id)">
      <div class="card-header d-flex flex-column">
        <div>
          {{data.creditor_name}}
        </div>
        <div class="d-flex justify-content-between">
          <div>
            <b>{{formatCurrency(data.amount)}}</b>
          </div>
          <span class="badge" :class="{'bg-success': data.is_assigned, 'bg-danger': !data.is_assigned }">
          <template v-if="data.is_assigned">
            {{$t('receipts.is_assigned')}}
          </template>
          <template v-if="!data.is_assigned">
            {{$t('receipts.is_not_assigned')}}
          </template>
        </span>
        </div>
      </div>
      <div class="card-body">
        <template  v-if="data.file_url">
          <vue-pdf-embed :page="1" :source="data.file_url" v-if="data.file_type === 'application/pdf'"/>
          <img style="max-width: 100%" :src="data.file_url" v-else />
        </template>
        <template v-else>
          file_url is missing
        </template>
      </div>
    </div>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed'

export default {
  name: 'DataviewContent',
  setup() {
    return {
    }
  },
  components: {
    VuePdfEmbed
  },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
    selectReceipt(id) {
      this.$emit('entitySelected', id)
    }
  }
}
</script>
<style lang="scss">
.card {
  &.selected {
    border: 1px solid red;
  }
}
</style>