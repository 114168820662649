<template>
  <div class="container-fluid h-100">
    <div class="row h-100">
      <div class="col-lg-6 d-none d-lg-flex tantum-bg">
        <img class="img-fluid" :src="require('@/assets/auth/login.svg')" alt="login image"/>
      </div>
      <div class="col-12 col-lg-6">
        <Form @submit="login(PROVIDERS.manet,  $event)" v-slot="{ errors }" :validation-schema="schema"
              class="register-form d-flex flex-column h-100">
          <div class="w-75 mx-auto mt-5">
            <div class="h2">Login</div>
            <div class="mb-3">
              <label class="form-label">{{ $i18n.t('auth.email') }}</label>
              <div class="input-group mb-3">
                <Field v-model="email" id="email" name="email" class="form-control"
                       :class="{'is-invalid': errors.email}" autofocus/>
                <span class="input-group-text" id="basic-addon1"><i class="fas fa-fw fa-trash" @click="clearEmail"></i></span>
              </div>
              <div v-if="errors.email" class="invalid-feedback">
                {{ errors.email }}
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $i18n.t('auth.password') }}</label>
              <Field type="password" v-model="password" id="password" name="password" class="form-control"
                     :class="{'is-invalid': errors.password}" autocomplete="current-password"/>
              <div v-if="errors.password" class="invalid-feedback">
                {{ errors.password }}
              </div>
            </div>
            <div class="mb3">
              <button type="submit" class="btn btn-primary">{{ $i18n.t('auth.login') }}</button>
              <!--              <span class="btn btn-primary ms-3" @click="login(PROVIDERS.manet,  $event)">{{$t('auth.login_manet')}}</span>-->
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import {PROVIDERS} from "@/scripts/constants";
import {useUserStore} from "@/stores/user";
import {Field, Form} from 'vee-validate';
import * as Yup from "yup";

export default {
  name: "LoginPage",
  components: {
    Field,
    Form,
  },
  setup() {
    const userStore = useUserStore()
    return {userStore}
  },
  data() {
    return {
      email: localStorage.getItem('tantum_email') || '',
      password: '',
      schema: Yup.object().shape({
        email: Yup.string().email(this.$t('validation.email')).required(this.$t('validation.required')),
        password: Yup.string().required(this.$t('validation.required')),
      }),
      PROVIDERS
    }
  },
  methods: {
    login(provider) {
      if (provider === this.PROVIDERS.manet) {
        this.userStore.login(provider, this.email, this.password).then(({data}) => {
          localStorage.setItem('tantum_email', this.email)
          this.userStore.loginSuccessful(data.token)
        })
            .catch(() => this.loginFailed())
      }
    },
    // loginSuccessful(token) {
    //   if (!token) {
    //     this.loginFailed()
    //     return
    //   }
    //   this.userStore.createUser(token)
    //   this.error = false
    //   if(this.userStore.user.specialRole && this.userStore.user.specialRole === SPECIAL_ROLES.taxOffice) {
    //       this.$router.push({name: ROUTES.ACCOUNTS.INDEX})
    //   } else {
    //       this.$router.push({name: ROUTES.DASHBOARD})
    //   }
    // },
    loginFailed() {
      this.userStore.logout()
    },

    clearEmail(){
      this.email = ''
      localStorage.removeItem('tantum_email')
    },
  },
  created() {

  },
}
</script>
