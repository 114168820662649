<template>
  <div>
    <DataTable stripedRows :lazy="true"
               :paginator="paginator"
               :rows="clientStore.serverOptions.rows"
               :totalRecords="clientStore.itemLength"
               dataKey="id"
               v-model:filters="filters"
               :filterDisplay="filterDisplay"
               :globalFilterFields="['kdnr','name']"
               :value="clientStore.clients"
               :loading="clientStore.loading"
               paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
               :rowsPerPageOptions="[10,20,50]"
               currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
               @page="onPage($event)"
               @filter="onFilter($event)"
               @sort="onSort($event)"
               :rowClass="({ active }) => [false, 0, '0'].includes(active) ? 'opacity-50' : ''"
               responsiveLayout="scroll">

      <TableColumn field="user_id" :header="this.$t('clients.user')">
        <template #filter="{filterModel, filterCallback}">
          <UserSelect v-model="filterModel.value" @update:modelValue="filterCallback" :short="true"/>
        </template>
        <template #body="{data}">
          <UserAvatar :id="data.user_id" />
        </template>
      </TableColumn>

      <template v-if="showColumnActive">
        <TableColumn field="active" :header="this.$t('clients.create.contact_active')">
          <template #body="{data}">
            <show-bool :value="data.active" />
          </template>
        </TableColumn>
      </template>

      <TableColumn field="kdnr" :sortable="true" :header="this.$t('clients.kdnr')">
        <template #filter="{filterModel,filterCallback}">
          <input type="search" v-model="filterModel.value" @input="filterCallback()" class="form-control"
                 style="max-width: 5em"/>
        </template>
      </TableColumn>
      <TableColumn field="name" :sortable="true" :header="this.$t('clients.name')">
        <template #filter="{filterModel,filterCallback}">
          <input type="search" v-model="filterModel.value" @input="filterCallback()" class="form-control"
                 style="max-width: 12em"/>
        </template>
      </TableColumn>
      <TableColumn field="client_type_id" :header="this.$t('clients.client_type')">
        <template #filter="{filterModel, filterCallback}">
          <select v-model="filterModel.value" @change="filterCallback()" class="form-select" style="max-width: 12em">
            <option :value="type.id" v-bind:key="key" v-for="(type, key) in setupStore.clientTypes">{{
                type.name
              }}
            </option>
          </select>
        </template>
        <template #body="{data}">
          {{ setupStore.getClientTypeById(data.client_type_id)?.name }}
        </template>
      </TableColumn>
      <TableColumn field="client_status_id" :header="this.$t('clients.client_status')">
        <template #filter="{filterModel, filterCallback}">
          <select v-model="filterModel.value" @change="filterCallback()" class="form-select" style="max-width: 12em">
            <option :value="status.id" v-bind:key="key" v-for="(status, key) in setupStore.clientStatuses">
              {{ status.name }}
            </option>
          </select>
        </template>
        <template #body="{data}">
          {{ setupStore.getClientStatusById(data.client_status_id)?.name }}
        </template>
      </TableColumn>
      <TableColumn field="address_city" :header="this.$t('addresses.city')"></TableColumn>
      <TableColumn field="url" :sortable="true" :header="this.$t('clients.url')">
        <template #body="{data}">
          <a :href="data.url" target="_blank" rel="noopener noreferrer" v-if="data.url"> {{ urlHost(data.url) }} </a>
          <template v-else>
            -
          </template>
        </template>
      </TableColumn>
      <TableColumn field="manet_url" :sortable="true" :header="this.$t('clients.manet_url')">
        <template #body="{data}">
          <a :href="data.manet_url" target="_blank" rel="noopener" v-if="data.manet_url"> {{
              urlHost(data.manet_url)
            }} </a>
          <template v-else>
            -
          </template>
        </template>
      </TableColumn>
      <TableColumn :header="this.$t('clients.operations')">
        <template #body="{data}">
          <div class="operations-wrapper">
<!--            <a class="operation-item" :href="`https://admin2.manet-marketing.de/manet/crm/${data.kdnr}?crm=zohocrm`"-->
<!--               title="ZohoCRM" target="_blank">-->
<!--              <i class="fas fa-handshake"/>-->
<!--            </a>-->
            <router-link class="operation-item" :to="{name: ROUTES.CLIENTS.SHOW, params: {id: data.id}}"
                         :title="$t('clients.show')">
              <i class="fas fa-eye"/>
            </router-link>
            <router-link class="operation-item" :to="{name: ROUTES.CLIENTS.EDIT, params: {id: data.id}}"
                         :title="$t('clients.edit.headline')">
              <i class="fas fa-edit"/>
            </router-link>
<!--            <router-link class="operation-item"-->
<!--                         :to="{name: ROUTES.CLIENTS.SHOW, params: {id: data.id}, query: {tab: 'contracts'}}"-->
<!--                         :title="$t('clients.contracts')">-->
<!--              <i class="fas fa-file"/>-->
<!--            </router-link>-->
<!--            <router-link class="operation-item" :to="{name: ROUTES.CONTRACTS.CREATE, query: {client_id: data.id}}"-->
<!--                         :title="$t('clients.contract')" target="_blank">-->
<!--              <i class="fas fa-file-signature"/>-->
<!--            </router-link>-->
            <!--            <span class="operation-item" @click="deleteClient(item)">-->
            <!--              <i class="fas fa-trash-alt"/>-->
            <!--            </span>-->
          </div>
        </template>
      </TableColumn>
    </DataTable>
  </div>
</template>

<script>

import {useClientStore} from "@/stores/client";
import {useSetupStore} from "@/stores/setup";
import {FilterMatchMode} from 'primevue/api';
import {PERMISSIONS, ROUTES} from "@/scripts/constants";
import {useUserStore} from "@/stores/user";
import ShowBool from "@/components/ShowBool.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import UserSelect from "@/components/UserSelect.vue";

export default {
  name: 'ClientsTable',
  components: {
    UserSelect,
    UserAvatar,
    ShowBool,
  },
  setup() {
    return {
      clientStore: useClientStore(),
      setupStore: useSetupStore(),
      userStore: useUserStore(),
    }
  },
  props: {
    paginator: {
      type: Boolean,
      default: false,
    },
    showFilters: {
      type: Boolean,
      default: false,
    },
    showColumnActive: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      filters: {
        contact_id: {value: null, matchMode: FilterMatchMode.EQUALS},
        kdnr: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        name: {value: null, matchMode: FilterMatchMode.CONTAINS},
        client_type_id: {value: null, matchMode: FilterMatchMode.EQUALS},
        client_status_id: {value: null, matchMode: FilterMatchMode.EQUALS},
        user_id: {value: null, matchMode: FilterMatchMode.EQUALS},
      },
      lastClientTypeId: undefined,
      timer: null
    }
  },
  created() {
    // first restore empty filters on new page
    this.setupStore.getClientTypes()
    this.setupStore.getClientStatuses()
    this.clientStore.serverOptions.filters = this.filters
    if ([ROUTES.CONTACTS.SHOW, ROUTES.CONTACTS.EDIT].includes(this.$route.name)) {
      this.filters.contact_id.value = this.$route.params.id
    }
    if (this.$route.query.kdnr) {
      this.filters.kdnr.value = this.$route.query.kdnr
    }
    if (this.$route.query.name) {
      this.filters.name.value = this.$route.query.name
    }
    this.clientStore.serverOptions.filters = this.filters
    this.getClients()
    this.setTitle(this.$tc('clients.client', 2).capitalize())
  },
  beforeUnmount() {
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS
    },
    ROUTES() {
      return ROUTES
    },
    filterDisplay() {
      return this.showFilters ? 'row' : null;
    },
  },
  methods: {
    urlHost(url) {
      const uri = new URL(url)
      return uri.host.replace(/^www\./, '');
    },
    deleteClient(item) {
      this.clientStore.delete(item)
    },
    onPage(event) {
      this.clientStore.serverOptions = event
      this.getClients()
    },
    onSort(event) {
      this.clientStore.serverOptions.sortField = event.sortField
      this.clientStore.serverOptions.sortOrder = event.sortOrder
      this.getClients()
    },
    getClients() {
      this.clientStore.index()
    },
    onFilter(data) {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.clientStore.serverOptions.filters = data.filters
        this.getClients();
      }, 500);
    },
  },
  watch: {
    // '$route.query.client_type_id': {
    //   handler: function (newValue) {
    //     if (this.lastClientTypeId !== newValue) {
    //       this.filters.client_type_id.value = this.$route.query.client_type_id
    //       this.clientStore.serverOptions.filters = this.filters
    //       this.getClients()
    //     }
    //     this.lastClientTypeId = newValue
    //   },
    //   deep: true,
    //   immediate: true
    // }
  }
}
</script>
<style lang="scss">
.p-filter-column {
  .p-column-filter-menu-button {
    display: none;
  }
}
</style>